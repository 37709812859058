/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
  url('roboto-v20-latin_cyrillic-ext-regular.eot?#iefix') format('embedded-opentype'),
  url('roboto-v20-latin_cyrillic-ext-regular.woff2') format('woff2'),
  url('roboto-v20-latin_cyrillic-ext-regular.woff') format('woff'),
  url('roboto-v20-latin_cyrillic-ext-regular.ttf') format('truetype'),
  url('roboto-v20-latin_cyrillic-ext-regular.svg#Roboto') format('svg');
}
/* roboto-500 - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('roboto-v20-latin_cyrillic-ext-500.eot?#iefix') format('embedded-opentype'),
  url('roboto-v20-latin_cyrillic-ext-500.woff2') format('woff2'),
  url('roboto-v20-latin_cyrillic-ext-500.woff') format('woff'),
  url('roboto-v20-latin_cyrillic-ext-500.ttf') format('truetype'),
  url('roboto-v20-latin_cyrillic-ext-500.svg#Roboto') format('svg');
}
